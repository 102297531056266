export const FILTERS = [
  '喜剧',
  '历史',
  '音乐',
  '家庭',
  '传记',
  '同性',
  '爱情',
  '古装',
  '科幻',
  '动作',
  '剧情',
  '武侠',
  '犯罪',
  '冒险',
  '恐怖',
  '奇幻',
  '悬疑',
  '惊悚',
  '情色',
  '鬼怪',
]

export const COLORS = {
  YELLOW: '#fff801',
}
